<template>
  <div class="page">
    <h1 class="page-title">{{ $i18n.t('contactUs.pricingInfo.Title') }}</h1>
    <p class="page-desc">{{ $i18n.t('contactUs.pricingInfo.Desc1') }}<br>{{ $i18n.t('contactUs.pricingInfo.Desc2') }}
    </p>
    <div class="container">
      <div class="pricing-info-form">
        <el-form ref="form" label-position="left" label-width="140px" :model="form" :rules="rules">
          <el-row :gutter="60">

            <el-col>
              <el-form-item :label="$i18n.t('contactUs.pricingInfo.Mandatory')" required/>
            </el-col>

            <el-col :md="12" :xs="24">
              <el-form-item :label="$i18n.t('contactUs.pricingInfo.FirstName')" prop="firstName">
                <el-input v-model="form.firstName" v-bind="inputAttr"/>
              </el-form-item>
            </el-col>
            <el-col :md="12" :xs="24">
              <el-form-item :label="$i18n.t('contactUs.pricingInfo.LastName')" prop="lastName">
                <el-input v-model="form.lastName" v-bind="inputAttr"/>
              </el-form-item>
            </el-col>


            <el-col :md="12" :xs="24">
              <el-form-item :label="$i18n.t('contactUs.pricingInfo.Email')" prop="email">
                <el-input v-model="form.email" v-bind="inputAttr"/>
              </el-form-item>
            </el-col>


            <el-col :md="12" :xs="24">
              <el-form-item :label="$i18n.t('contactUs.pricingInfo.Telephone')" prop="telephone">
                <el-input v-model="form.telephone" v-bind="inputAttr" placeholder="Format: Area Code-Number eg. 0066-123456"/>
              </el-form-item>
            </el-col>
            <el-col :md="12" :xs="24">
              <el-form-item label="Area" prop="area">
                <el-select v-model="form.area" v-bind="inputAttr">
                  <el-option label="Africa" value="Africa"></el-option>
                  <el-option label="Asia Pacific" value="Asia Pacific"></el-option>
                  <el-option label="Europe" value="Europe"></el-option>
                  <el-option label="Latin America" value="Latin America"></el-option>
                  <el-option label="Middle East" value="Middle East"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12" :xs="24">
              <el-form-item :label="$i18n.t('contactUs.pricingInfo.CountryRegion')" prop="country">
                <el-input v-model="form.country" v-bind="inputAttr"/>
              </el-form-item>
            </el-col>


            <el-col :md="12" :xs="24">
              <el-form-item :label="$i18n.t('contactUs.pricingInfo.Company')" prop="company">
                <el-input v-model="form.company" v-bind="inputAttr"/>
              </el-form-item>
            </el-col>
            <el-col :md="12" :xs="24">
              <el-form-item :label="$i18n.t('contactUs.pricingInfo.Industry')" prop="industry">
                <el-input v-model="form.industry" v-bind="inputAttr"/>
              </el-form-item>
            </el-col>


            <el-col :md="12" :xs="24">
              <el-form-item :label="$i18n.t('contactUs.pricingInfo.JobTitle')" prop="jobTitle">
                <el-select v-model="form.jobTitle" clearable filterable>
                  <el-option v-for="(option,index) in JobTitle" :key="index" :label="option.name"
                             :value="option.code"/>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col>
              <el-form-item prop="description" label-width="0">
                <el-input :class="{'has-value':form.description}" v-model="form.description" type="textarea"
                          :autosize="{ minRows: 5, maxRows:8}" :maxlength="4000"
                          :placeholder="$i18n.t('contactUs.pricingInfo.BrieflyPlaceholder')"/>
              </el-form-item>
            </el-col>

            <el-col>
              <el-form-item prop="isSubscribe" label-width="0" hide-required-asterisk>
                <div class="agree-field">
                  <div class="agree-field-text">{{ $i18n.t('contactUs.pricingInfo.ConfirmText') }}</div>
                  <el-radio-group v-model="form.isSubscribe">
                    <el-radio :label="true">{{ $i18n.t('contactUs.pricingInfo.Yes') }}</el-radio>
                    <el-radio :label="false">{{ $i18n.t('contactUs.pricingInfo.No') }}</el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
            </el-col>

            <el-col>
              <el-form-item prop="isAgree" label-width="0" hide-required-asterisk>
                <div class="check-field">
                  <el-checkbox v-model="form.isAgree" :false-label="null"></el-checkbox>
                  <div class="check-field-wrap">
                    <span class="check-field-text">{{ $i18n.t('contactUs.pricingInfo.AgreeText') }}</span>
                    <span class="check-field-link">{{ $i18n.t('contactUs.pricingInfo.PrivacyPolicy') }}</span>
                    <span class="check-field-text">{{ $i18n.t('contactUs.pricingInfo.And') }}</span>
                    <span class="check-field-link">{{ $i18n.t('contactUs.pricingInfo.TermsOfUse') }}</span>
                  </div>
                </div>
              </el-form-item>
            </el-col>

          </el-row>

          <div>
            <div class="submit-btn" @click="submit" v-loading.fullscreen.lock="fullscreenLoading">
              {{ $i18n.t('contactUs.pricingInfo.submit') }}
            </div>
          </div>

        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
  import {initDicts} from '@/utils/commons';
  import {preSalesSave} from '@/api/contactUs';
  import {cloneDeep} from 'lodash';

  export default {
    name: 'PricingInfo',
    data() {
      let validatorEmail = (rule, value, callback) => {
        if (value.length > 254) {
          callback(new Error(this.$i18n.t('contactUs.pricingInfo.length-error')));
        } else if (value.indexOf('@') === -1) {
          callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.at-error')));
        } else if (!/^(\w|[!#$%&’*+-/=?^`{}|~.])+@[^@]*$/.test(value)) {
          // invalidLetter = value.replace(/@[^@]+$/, '').replace(/\w|[!#$%&’*+-/=?^`{}|~.]/g, '');
          callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.account-letter-forbidden')));
        } else if (/[.]{2}/.test(value)) {
          callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.double-dot-error')));
        } else if (!/^.{1,63}@[^@]*$/.test(value)) {
          callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.account-length-error')));
        } else if (!(/(^[^.].*@[^@]*$)/.test(value) && /^.*[^.]@[^@]*$/.test(value))) {
          callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.prevDot-error')));
        } else if (!/^[^@]+@([0-9]|[A-Z]|[a-z]|[\-.])+$/.test(value)) {
          // invalidLetter = value.replace(/^[^@]+@/, '').replace(/[A-Za-z0–9\-.]/g, '');
          callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.nextLetter-forbidden')));
        } else if (!(/^[^@]+@[^-].*$/.test(value) && /^[^@]+@.*[^-]$/.test(value))) {
          callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.nextLine-error')));
        } else if (!/^[^@]+@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)) {
          callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.domain-error')));
        } else {
          callback();
        }

      };

      let fieldRequired = (trigger = 'blur') => {
        return {
          required: true,
          message: this.$i18n.t('contactUs.pricingInfo.fieldRequired'),
          trigger,
        };
      };

      return {
        fullscreenLoading: false,

        form: {
          'firstName': '',
          'lastName': '',
          'budget': '',
          'company': '',
          'consultDate': '',
          'area': '',
          'country': '',
          'description': '',
          'email': '',
          'favoriteProducts': '',
          'industry': '',
          'jobTitle': '',
          'telephone': '',
          'isSubscribe': null,
          'isAgree': null,
        },

        rules: {
          firstName: fieldRequired('blur'),
          lastName: fieldRequired('blur'),
          email: [
            fieldRequired('blur'),
            {required: true, validator: validatorEmail, trigger: 'blur'},
          ],
          telephone: [
            fieldRequired('blur'),
            {
                required: true, validator: (rele, value, callback) => {
                  if (value) {
                    if (/^((00)|\+)\d+$/.test(value)) {
                      callback();
                    } else {
                      callback(new Error(this.$i18n.t('contactUs.pricingInfo.phoneFormatError')));
                    }
                  } else {
                    callback(new Error(this.$i18n.t('contactUs.pricingInfo.fieldRequired')));
                  }
                }, trigger: ['blur', 'change']
            }
          ],
          area: fieldRequired('blur'),
          country: fieldRequired('blur'),
          company: fieldRequired('blur'),
          industry: fieldRequired('blur'),
          isSubscribe: fieldRequired('change'),
          isAgree: [
            fieldRequired('change'),
            {
              required: true, validator: (rule, value, callback) => {
                if (!value) {
                  callback(new Error(this.$i18n.t('contactUs.pricingInfo.fieldRequired')));
                } else {
                  callback();
                }
              }, trigger: 'change',
            },
          ],
        },

        inputAttr: {
          placeholder: this.$i18n.t('contactUs.pricingInfo.placeholder'),
          maxlength: '100',
          clearable: true,
        },

        JobTitle: [],
        FavoriteProducts: [],
        Budget: [],

        prevPagePath: '/',
      };
    },
    mounted() {
      initDicts(['JobTitle']).then(res => {
        this.JobTitle = res.JobTitle;
      });
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.prevPagePath = from.path;
      });
    },
    methods: {
      submit() {
        this.$refs.form.validate((valid) => {
          let bodyData = cloneDeep(this.form);
          if (valid) {
            this.fullscreenLoading = true;
            preSalesSave(bodyData).then(() => {
              this.fullscreenLoading = false;
              this.$message.success(this.$t('tipInfo.submitSuccess'));
              this.$router.push({path: this.prevPagePath});
            }).catch(() => {
              this.fullscreenLoading = false;
              this.$message.error(this.$t('tipInfo.submitFailed'));
            });
          }
        });
      },
    },
  };
</script>

<style scoped lang="less">
  .page {
    position: relative;
    padding-bottom: 20px;
    background-color: #F8F8F8;

    &:after {
      content: '';
      background-color: #F8F8F8;
      position: absolute;
      height: 100vh;
      left: 0;
      top: 0;
      right: 0;
      z-index: -1;
    }
  }

  .page-title {
    text-align: center;
    padding: 50px 0 26px;
    font-size: 42px;
    font-weight: 400;
    color: #000000;
  }

  .page-desc {
    font-size: 18px;
    font-weight: 400;
    color: #666666;
    text-align: center;
    margin-bottom: 26px;
  }


  .pricing-info-form {
    background-color: #FFFFFF;
    padding: 40px 60px;
    transition: .3s;

    @media (max-width: 750px) {
      & {
        padding: 30px;
      }

      .submit-btn {
        height: 50px;
        max-width: 100%;
      }
    }



    /deep/ .el-form-item {
      //display: flex;
      //width: 100%;
      //margin-bottom: 30px;

      //.el-form-item__label {
      //  white-space: nowrap;
      //}

      .el-input, .el-select {
        width: 100%;
      }

      //.el-form-item__content {
      //  flex: 1;
      //  width: 100%;
      //}

      .el-textarea {
        &.has-value:after {
          display: none;
        }

        &:after {
          content: '*';
          color: #f56c6c;
          position: absolute;
          top: 0;
          left: 7px;
          line-height: 32px;
        }
      }
    }

    .agree-field {
      .agree-field-text {
        font-weight: 400;
        color: #666666;
        line-height: 1.6;

        &:before {
          content: '*';
          color: #f56c6c;
          margin-right: 4px;
        }
      }
    }

    .check-field {
      line-height: 1.6;
      display: flex;

      .check-field-wrap {
        flex: 1;
        width: 100%;
      }

      .el-checkbox {
        margin-right: 10px;
      }

      .check-field-text {
        color: #999999;
        font-weight: 400;
      }

      .check-field-link {
        color: #333333;
        font-weight: 400;
        padding: 0 5px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        &:active {
          opacity: .7;
        }
      }
    }
  }

  .submit-btn {
    max-width: 250px;
    height: 60px;
    background: #C7000B;
    border-radius: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    margin: 0 auto;
    cursor: pointer;
    user-select: none;
    transition: .1s ease-out;
    margin-top: 20px;

    &:hover {
      opacity: .8;
    }

    &:active {
      opacity: 1;
    }
  }
</style>
